.testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.testimonials::after {
    background-image: url("../../../public/images/testimonials-bg.png");
}
.testimonials .box {
    max-width: 60%;
}
.testimonials i {
    background-color: var(--white);
    font-size: 20px;
    padding: 15px;
    margin: 30px 0;
    display: inline-block;
    color: var(--primaryColor);
}
.testimonials .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: auto;
    border-radius: 50%;
    border: 3px solid var(--primaryColor);
}
.testimonials img {
    width: 100px;
    height: 100px;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .testimonials {
        padding-top: 0rem;
    }
}