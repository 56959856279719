.services {
    position: relative;
    margin: 8rem auto;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    height: 100%;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    width: auto;
    margin: 0 auto;
    margin-top: 2rem;
}

.buttons {
    height: auto;
    display: block;
    width: auto;
    margin-top: 2rem;
}
@media screen and (min-width: 320px) and (max-width:992px){
    .services .box {
        height: auto;
    }
}
@media screen and (min-width: 450px) and (max-width:1290px){
    .services-grid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.services h2 {
    font-size: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    text-align: left;
    margin: 0;
}
.services .box {
    background-color: var(--primaryBackground);
    padding: 2rem;
    transition: transform 0.7s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40vh;
}
.services i {
    color: var(--primaryColor);
}
.services h3 {
    margin-top: 20px;
}
