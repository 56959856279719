#blogDetails {
    height: auto;
    margin-top: 5rem;
    display: block;
}

#blogDetails h1 {
    /* border-left: 8px solid var(--cyan-color); */
    padding-left: 20px;
}

#blogDetails h2 {
    color: var(--sliver);
    font-weight: bold;
    font-size: larger;
    margin: 20px 0;
    border-left: 4px solid var(--cyan-color);
    padding-left: 20px;
}

#blogDetails h3 {
    color: var(--sliver);
    font-size: large;
    margin: 20px 0;
    border-left: 2px solid var(--cyan-color);
    padding-left: 20px;
}

#blogDetails section {
    border-top: 1px solid var(--beige-color);
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    padding: 20px 0;
    width: auto;
}

#blogDetails li {
    color: var(--sliver);
    list-style: auto;
    padding: 10px;
}

#blogDetails ul {
    margin-left: 20px;
}

#blogDetails .wrapper-blog img,
#blogDetails .wrapper-blog video {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
}