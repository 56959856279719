
.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    width: 100vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    gap: 2.5rem;
    margin-top: 3rem;
}
.wrapper-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.services h2 {
    font-size: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    text-align: left;
    margin: 0;
}


#servicesDetails {
    height: auto;
    margin-top: 5rem;
    display: block;
}
.servicesDetails {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}
.servicesDetails h2 {
    font-size: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    text-align: left;
    margin: 0;
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 1px 5px var(--cyan-color);
    padding: 1rem;
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
}
.card h2 {
    font-size: 20px;
    color: var(--primaryColor);
    font-weight: 600;
    text-align: left;
    margin: 0;
}

ul.bulletList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    flex-grow: 1; 
}

.bulletItem {
    display: flex;
    align-items: center;
    color: whitesmoke;
    cursor: pointer;
    gap: 0.8rem;
    padding: 0.2rem;
}

.circle-number {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--cyan-color);
    color: #fff;
    font-weight: bold;
    margin-right: 8px;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 0;
}

.buttons-left {
    justify-content: flex-start;
}

@media screen and (max-width: 768px) {
    .services-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .services .box {
        /* height: 85vh; */
    }
    .buttons {
        margin-top: 0;
    }
    .grid {
        width: auto;
    }
}
@media screen and (min-width: 1024px) {
    .services-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .grid {
        width: auto;       
    }

}