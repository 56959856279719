

.hero-home {
    position: relative;
}
.hero-home::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../../public/images/home/1.png");
    background-size: cover;
    background-attachment: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
}

.hero.counter::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100%;
    height: 50vh;
    background-image: url("../../../public/images/banner-bg.png");
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 10;
    width: 100%;
    height: 100vh;
    content: "";
}


.heroContent {
    max-width: 100%;
    margin-left: 5rem;
    z-index: 15;
}

.heading {
    font-size: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    margin-bottom: 20px;
    border-left: 2px solid var(--cyan-color);
    padding-left: 20px;
}
.hero-title {
    font-size: 60px;
    font-weight: 600;
    color: var(--beige-color);
}
.hero-line {
    border-top: 1px solid;
    padding: 0.5rem;
    color: var(--beige-color);
    width: 50vh;
    margin: 0 auto;
    margin-top: 2rem;
}
.hero-sub-title {
    font-size: 30px;
    color: var(--beige-color);
    z-index: 15;
}
.hero-action {
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {
    .hero {
      height: auto;
    }
    .hero-title {
        font-size: 30px;
    }
    .hero-sub-title {
        font-size: 21px;
    }
    .hero-home::after {
        width: 100vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: unset;
    }
    .hero.counter::after {
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: local;
        height: 90vh;
        height: -moz-available;           /* For Firefox */
        height: -webkit-fill-available;   /* For WebKit (Safari, Chrome) */
        height: -ms-fill-available;       /* For IE/Edge */
    }
    .hero-action {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
    .heroContent {
        max-width: 80%;
        margin-top: 15rem;
        margin-left: 3rem;
    }
    .hero::after {
        background-size: cover;
        object-fit: cover;
        width: 100%;
        height: 60vh;
        background-attachment: unset;
    }
}

@media screen and (min-width: 1024px) {
    .hero.counter::after {
        height: 60vh;
        background-size: cover;
        background-attachment: fixed;
    }
}

@media screen and (min-width: 1290px) {
    .hero.counter::after {
        height: 50vh;
    }
}

@media screen and (max-width: 1024px) {
    .box-about {
        display: inline-table;
        padding: 0.5rem;
    }
    .hero-line {
        width: 30vh;
    }
}

@media screen and (min-width: 320px) and (max-width: 376px){
    .hero-line {
      width: 25vh;
    }
    .contactBox {
        padding: 0;
        margin: 0;
    }
    .contactLine {
        width: 25vh;
    }
}

@media screen and (min-width: 320px) and (max-width: 426px){
    .hero-line {
        width: 25vh;
    }
    .contactLine {
        width: 25vh;
    }
    .contact .right {
        padding: 0;
    }
    .hero.counter::after {
        height: 90vh;
    }
}