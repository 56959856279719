#mentionsLegales {
    height: auto;
}

#mentionsLegales h2 {
    color: var(--sliver);
    font-weight: bold;
    font-size: larger;
    margin: 20px 0;
    border-left: 4px solid var(--cyan-color);
    padding-left: 20px;
}

#mentionsLegales h3 {
    color: var(--sliver);
    font-size: large;
    margin: 20px 0;
    padding-left: 20px;
}

#mentionsLegales section {
    border-top: 1px solid var(--beige-color);
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    padding: 20px 0;
    height: 100%;
}

#mentionsLegales li {
    color: var(--sliver);
    list-style: auto;
    padding: 10px;
}

#mentionsLegales ul {
    margin-left: 20px;
}

.wrapper {
    padding: 20px 0;
}