@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --beige-color: #E6DCCA;
  /* --blue-color: #05345C; */
  --cyan-color: #13b1c5;

  --blue-color: #1F497D;
  --green-color: #9ABB59;


  --primaryBackground: #212222;
  --secondaryBackground: #2f3030;
  --primaryColor: #E6DCCA;
  --secondaryColor: #05345C;

  --greyDark: #7d8792;
  --headingColor: #2d2e2e;
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
}
body {
  background-color: var(--headingColor);
  font-family: "Montserrat", sans-serif;
}
a {
  color: var(--white);
  text-decoration: none;
  transition: 0.5s;
}
a:hover {
  color: var(--beige-color);
}
p {
  color: var(--sliver);
  font-size: 16px;
  line-height: 30px;
  margin: 20px 0;
  text-align: justify;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.container {
  max-width: 80%;
  margin: auto;
}
.flexsb {
  display: flex;
  justify-content: space-between;
}
button {
  font-size: 17px;
  padding: 1rem 2rem;
  margin-right: 1rem;
  cursor: pointer;
  color: white;
  border: none;
  transform: scale(1);
  transition: 0.2s!important;
}
.button-primary {
  background: var(--secondaryColor);
}
.button-secondary {
  background: var(--primaryColor);
  color: var(--headingColor);
}

.button-primary:hover {
  box-shadow: inset 8px 0 0 var(--primaryColor);
}
.button-secondary:hover {
  box-shadow: inset 8px 0 0 var(--cyan-color);
}

button:disabled {
    z-index: 10;
    cursor: not-allowed;
}

.fontSize {
  font-size: 20px;
}

.mailTo {
  margin: 2px;
}

.primaryBtn {
  background: none;
  border: 2px solid var(--primaryColor);
}

.align-items-center  {
  align-items: center;
}

.beige-shadow {
  box-shadow: 2px 2px 4px rgba(230, 220, 202, 0.2);
}
.ml-10 {
  margin-left: 10rem;
}
.black {
  color: var(--black);
}

footer {
  position: relative;
  text-align: center;
}


section {
  height: 95vh;
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.grid4 {
  grid-template-columns: repeat(4, 1fr);
  height: 50vh;
  place-items: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 16px;
  }
}


article {
  margin-top: 50px;
  margin-bottom: 80px;
}
article img {
  width: 100%;
  height: 100%;
}
article .box {
  position: relative;
  transition: 0.5s;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
}
.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6dccab3;
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
article .box:hover .overlay::after,
article .box:hover .overlay {
  opacity: 1;
}
.catButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}
.catButton button {
  text-transform: capitalize;
  margin-right: 20px;
}

.white {
  color: var(--white);
}


.contact {
  margin: 20px 0;
}
.contact .right,
.contact .left {
  padding: 20px;
}
.contact .flex input:nth-child(1) {
  margin-right: 10px;
}
.contact button,
.contact textarea,
.contact input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 5px;
}
.contactLine {
  border-bottom: 1px solid var(--beige-color);
  width: 40vh;
}
.contactBox {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.contactBox i {
  color: var(--beige-color);
}
.contactBox svg {
  margin-right: 5px;
}

footer {
  text-align: center;
  background-color: var(--primaryBackground);
  padding: 70px;
  margin-top: 50px;
}
footer i {
  margin: 5px;
  color: var(--primaryColor);
}
footer a {
  color: var(--primaryColor);
}
footer p {
  text-align: center;
}

@media screen and (max-width: 768px) {
  section {
    padding-top: 3rem;
    height: 100vh;
    width: 100vw;
    display: block;
  }
  .contact .flexsb,
  .about .flex {
    flex-direction: column;
  }
  .right,
  .left {
    width: 100%;
  }
  .grid4,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }
  article {
    margin: 0;
    padding-bottom: 50px;
  }
  .catButton {
    flex-wrap: wrap;
    row-gap: 1rem;
  }
}

.debug {
  border: 3px solid red;
}

@media screen and (min-width: 320px) and (max-width:992px){
  .contactBox {
      padding: 0;
      margin: 0;
  }
  .mailTo {
    font-size: .9rem;
  }
  .flexsb {
    flex-wrap: wrap;
  }
}

.about-heading {
  border-left: 2px solid var(--cyan-color);
  padding-left: 20px;
}